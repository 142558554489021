.footer {
    background-color: $black;
    position: relative;
    z-index: 99;
    padding-bottom: 32px;

    h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $green;
    }

    .footer-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 70px;

        .footer-menu {
            &.grid {
                flex: 1;
            }

            h6 {
                color: $green;
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                margin-bottom: 20px;
                text-transform: none;
                white-space: nowrap;
            }

            ul {
                li {
                    a {
                        color: #fff;
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 500;
                        margin-bottom: 12px;
                        display: block;
                    }
                }
            }
        }

        .footer-logo {
            height: 100%;

            .logo-holder {
                margin-bottom: 30px;

                img {
                    width: 140px;
                    min-width: initial;
                }
            }

            .phones-wrapper {

                .phones {

                    span {
                        color: $green;
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;
                    }

                    a {
                        color: #fff;
                        display: block;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 28px;
                        padding-left: 4px;
                    }
                }
            }
        }

        .footer-contact-form {
            .button {
                background-color: #26c438;
                border: none;
                color: #fff;
                font-weight: bold;
                padding: 9.5px 20px;
                width: 100%;
            }
        }

    }

    .footer-social {
        ul.social {
            display: flex;
            justify-content: space-between;

            li {
                a {                
                    svg {
                        height: 26px;
                        width: auto;
                    }
                }
            }
        }
    }

    .cancellation-warning {
        background: url("#{$images-path}title-divider.svg") top left no-repeat;
        background-size: 100%;
        color: #fff;
        padding-top: 70px;

        .slogan {
            display: flex;
            align-items: center;
            justify-content: center;

            .bordered {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                border-top: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
                padding: 3px 0;
            }

            .hours {
                font-weight: 500;
                font-size: 34px;
                line-height: 41px;
                margin: 0 9px;
            }
        }

        .warning {
            margin-top: 5px;
            opacity: 0.5;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
        }
    }

    .footer-end {
        padding: 10px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;

        .footer-end-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ul {
                display: flex;
                align-items: center;
                margin: 0;

                li {
                    margin-left: 30px;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .activate-zendesk {
        border-radius: 30px;
        background-color: $green;
        padding: 12px 18px;
        color: #fff;
        position: fixed;
        bottom: 20px;
        right: 20px;
        border: none;
        font-weight: bold;
        font-size: 14px;
        display: inline-flex;
        align-items: center;

        i {
            margin-right: 8px;
        }
    }

    @include media("<=tablet") {
        .footer-wrapper {
            flex-direction: column;

            .footer-menu {
                width: 100%;
                padding: 0;
                justify-content: space-between;
                margin: 0;
                flex-wrap: wrap;

                &.grid {
                    flex: 1;

                    .footer-logo {
                        flex-basis: 100%;
                        max-width: 100%;
                        text-align: center;
                        margin-bottom: 30px;
                    }

                    .footer-contact-form {
                        flex-basis: 100%;
                        max-width: 100%;
                        margin-bottom: 30px;
                    }
                }

                .menu-block {
                    flex-basis: 50%;
                    max-width: 50%;
                    margin-right: 0;
                }

                h6 {
                    font-size: 14px;
                    margin-bottom: 8px;
                }

                ul {
                    margin-bottom: 20px;

                    li a {
                        margin-bottom: 8px;
                        font-size: 12px;
                        line-height: 14.4px;
                    }
                }
            }        
        }

        .cancellation-warning {
            background: url("#{$images-path}title-divider-mobile.svg") top left no-repeat;
            background-size: 100%;
            margin-top: -20px;

            .slogan {
                flex-direction: column;
            }

            .warning {
                text-align: center;
                margin-bottom: 14px;
            }
        }

        .footer-end {

            .footer-end-wrapper {
                flex-direction: column-reverse;

                ul {
                    width: 100%;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    padding-bottom: 12px;
                    margin: 0 -9px;
                    justify-content: center;

                    li {
                        margin: 0 20px;
                    }
                }

                .copyright {
                    padding: 12px 0;
                }
            }
        }

        .footer-social, .footer-end{
            max-width: 100% !important;
            flex-basis: 100% !important;
        }
    }

    @include media("<=mobile-landscape") {
        .activate-zendesk {
            display: none;
        }
    }
}

@media (max-width: 500px) {

    .grecaptcha-badge,
    #_hj_feedback_container {
        display: none;
    }
}